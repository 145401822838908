import React, { Component } from 'react';
import moment from 'moment';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      data: [],
      eventData: []
    }
  }

  fetchMintData = () => {
    console.log("Requesting data...")
    return fetch(`https://ginseng.finance/v1/mints/${moment().startOf('hour').subtract('24', 'hours').valueOf()}/${moment().endOf('minute').valueOf()}?limit=1000&page=1`)
      .then(res => res.json())
      .then(result => {
        console.log(result)
        if(result.success) {

          var allArrays = []
          result.data.results.map(x => {
            console.log("x", x.events)
            allArrays = allArrays.concat(x.events);
          })

          var allCounterparty = []
          var allMinters = {}
          var allCollections = []
          var allEvents = []
          allArrays.map(x => {
            if(!allCounterparty.includes(x.counterparty)) {
              allCounterparty.push(x.counterparty)
            }

            if(!allMinters[x.from]) {
              allMinters[x.from] = {}
            }

            if(!allMinters[x.from][x.counterparty]) {
              allMinters[x.from][x.counterparty] = new Date();
            }

            console.log("x.timestamp", new Date(allMinters[x.from][x.counterparty]), new Date(x.timestamp), x)

            if(new Date(allMinters[x.from][x.counterparty]) > new Date(x.timestamp)) {
              allMinters[x.from][x.counterparty] = new Date(x.timestamp);
            }

            allEvents.push({address: x.from, timestamp: x.timestamp, counterparty: x.counterparty})

          })

          console.log("allCounterparty", allCounterparty)
          console.log("allMinters", Object.keys(allMinters))
          var allMintDates = {}
          Object.keys(allMinters).map(x => {
            var allProjects = Object.keys(allMinters[x]);
            console.log(`${x}`, allProjects)
            return allProjects.map(y => {
              if(!allCollections[y]) {
                allCollections[y] = 0;
              }

              if(!allMintDates[y]) {
                allMintDates[y] = new Date()
              }

              if(new Date(allMinters[x][y]) < new Date(allMintDates[y])) {
                allMintDates[y] = new Date(allMinters[x][y]);
              }

              allCollections[y] = allCollections[y] + 1;
            })
          })

          var testObj = Object.keys(allCollections).map(x => {
            return {address: x, count: allCollections[x], mintDate: allMintDates[x]}
          }).sort((a,b) => {
            if(a.count >= b.count) {
              return -1;
            } else if (a.count < b.count) {
              return 1;
            } else {
              return 0;
            }
          })

          var testEvents = allEvents.sort((a,b) => {
            if(a.timestamp >= b.timestamp) {
              return -1;
            } else if (a.timestamp < b.timestamp) {
              return 1;
            } else {
              return 0;
            }
          })
          console.log("testObj", testObj)
          console.log("allCollections", allCollections)
          this.setState({ 'data': testObj, eventData: testEvents, isLoaded: true});
        } else {
          console.error("HTTP Error!")
          this.setState({ 'data': [], eventData: [], isLoaded: false, error: 'http error'});
          throw new Error("HTTP error")
        }
      })
  }

  componentDidMount() {
    console.log("Rendered.")
    this.fetchMintData();
  }

  render() {
    return (
      <div className="App">
        <main className="main-content">
          <h2 style={{color: 'white', paddingBottom: '24px'}}>Mint Master</h2>
          <div className="row">
            <div className="col">
              <article className="article-class">
                <h4 style={{color: 'white', paddingBottom: '24px'}}>Latest Mints</h4>
                <section className="section-class">
                  <div className="col" style={{ width: '100%' }}>
                    <div className="pos-relative">
                      {/*Table*/}
                      {this.state.isLoaded ? (
                      <div className="table-container" data-target="table">
                        {/*Header*/}
                        <div className="header-container" data-row="header">
                          <div className="header-cell" data-target="cell">
                            <div data-target="inner" data-type="nft_token" className="header-cell-text" style={{maxWidth: "250px"}}>
                              <span className="header-cell-span">
                                <span>NFT collection</span>
                                </span>
                            </div>
                          </div>
                          <div className="header-cell" data-target="cell">
                           <div data-target="inner" data-type="nft_token" className="header-cell-text" style={{maxWidth: "250px"}}>
                             <span className="header-cell-span">
                               <span>FOMO Level</span>
                             </span>
                           </div>
                          </div>
                          <div className="header-cell" data-target="cell">
                           <div data-target="inner" data-type="nft_token" className="header-cell-text" style={{maxWidth: "250px"}}>
                             <span className="header-cell-span">
                               <span>Minters (15 min)</span>
                             </span>
                           </div>
                          </div>
                          <div className="header-cell" data-target="cell">
                           <div data-target="inner" data-type="nft_token" className="header-cell-text" style={{maxWidth: "250px"}}>
                             <span className="header-cell-span">
                               <span>Avg Price (ETH)</span>
                             </span>
                           </div>
                          </div>
                          <div className="header-cell" data-target="cell">
                           <div data-target="inner" data-type="nft_token" className="header-cell-text" style={{maxWidth: "250px"}}>
                             <span className="header-cell-span">
                               <span>Total Minters</span>
                             </span>
                           </div>
                          </div>
                          <div className="header-cell" data-target="cell">
                           <div data-target="inner" data-type="nft_token" className="header-cell-text" style={{maxWidth: "250px"}}>
                             <span className="header-cell-span">
                               <span>Smart Minters</span>
                             </span>
                           </div>
                          </div>
                          <div className="header-cell" data-target="cell">
                           <div data-target="inner" data-type="nft_token" className="header-cell-text" style={{maxWidth: "250px"}}>
                             <span className="header-cell-span">
                               <span>Total NFTs Minted</span>
                             </span>
                           </div>
                          </div>
                          <div className="header-cell" data-target="cell">
                           <div data-target="inner" data-type="nft_token" className="header-cell-text" style={{maxWidth: "250px"}}>
                             <span className="header-cell-span">
                               <span>First Mint</span>
                             </span>
                           </div>
                          </div>
                        </div>
                        {/*Row 0*/}
                        {
                          this.state.data.slice(0,20).map( (itemObj, itemIndex) => {
                            return (
                              <div className="cell-container" data-row={"row-" + itemIndex}>
                                <a className="table-cell" data-target="cell" data-column="0" target="_blank" role="button" href={`https://etherscan.io/address/${itemObj.address}`}>
                                  <div className="table-cell-text">
                                    <span className="table-cell-span">
                                      <span>
                                        <span>{itemObj.address}</span>
                                      </span>
                                    </span>
                                  </div>
                                </a>
                                <div className="table-cell" data-target="cell" data-column="1">
                                  <div className="table-cell-text">
                                    <span className="table-cell-span">
                                      <span>N/A</span>
                                    </span>
                                  </div>
                                </div>
                                <div className="table-cell" data-target="cell" data-column="2">
                                  <div className="table-cell-text">
                                    <span className="table-cell-span">
                                      <span>0</span>
                                    </span>
                                  </div>
                                  <div>
                                    <div data-target="sparkline" className="spark-cell">
                                      <div className="spark-cell-inner" style={{width: '0%'}}></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="table-cell" data-target="cell" data-column="3">
                                  <div className="table-cell-text">
                                    <span className="table-cell-span">
                                      <span>N/A</span>
                                    </span>
                                  </div>
                                </div>
                                <div className="table-cell" data-target="cell" data-column="5">
                                  <div className="table-cell-text">
                                    <span className="table-cell-span">
                                      <span>0</span>
                                    </span>
                                  </div>
                                  <div>
                                    <div data-target="sparkline" className="spark-cell">
                                      <div className="spark-cell-inner" style={{width: '0%'}}></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="table-cell" data-target="cell" data-column="5">
                                  <div className="table-cell-text">
                                    <span className="table-cell-span">
                                      <span>{itemObj.count}</span>
                                    </span>
                                  </div>
                                  <div>
                                    <div data-target="sparkline" className="spark-cell">
                                      <div className="spark-cell-inner" style={{width: (itemObj.count+"%")}}></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="table-cell" data-target="cell" data-column="6">
                                  <div className="table-cell-text">
                                    <span className="table-cell-span">
                                      <span>N/A</span>
                                    </span>
                                  </div>
                                </div>
                                <div className="table-cell" data-target="cell" data-column="7">
                                  <div className="table-cell-text">
                                    <span className="table-cell-span">
                                      <span>{moment(itemObj.mintDate).format("LLLL")}</span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                        {/*Row N+1*/}
                      </div>
                      ) : null}
                    </div>
                    {/*<footer className="">
                      <div className="">{'Showing 1 to 20 of 500'}</div>
                      <div className="">{'< [1] 2 ... 25 >'}</div>
                    </footer>*/}
                  </div>
                </section>
              </article>
            </div>
          </div>
        </main>

        <main className="main-content">
          <h2 style={{color: 'white', paddingBottom: '24px'}}>NFT Smart Money</h2>
          <div className="row">
            <div className="col">
              <article className="article-class">
                <h4 style={{color: 'white', paddingBottom: '24px'}}>Smart Money Tracker</h4>
                <section className="section-class">
                  <div className="col" style={{ width: '100%' }}>
                    <div className="pos-relative">
                      {/*Table*/}
                      {this.state.isLoaded ? (
                      <div className="table-container" data-target="table">
                        {/*Header*/}
                        <div className="header-container" data-row="header">
                          <div className="header-cell" data-target="cell">
                            <div data-target="inner" data-type="nft_token" className="header-cell-text" style={{maxWidth: "250px"}}>
                              <span className="header-cell-span">
                                <span>Name</span>
                                </span>
                            </div>
                          </div>
                          <div className="header-cell" data-target="cell">
                           <div data-target="inner" data-type="nft_token" className="header-cell-text" style={{maxWidth: "250px"}}>
                             <span className="header-cell-span">
                               <span>Type</span>
                             </span>
                           </div>
                          </div>
                          <div className="header-cell" data-target="cell">
                           <div data-target="inner" data-type="nft_token" className="header-cell-text" style={{maxWidth: "250px"}}>
                             <span className="header-cell-span">
                               <span>Quantity</span>
                             </span>
                           </div>
                          </div>
                          <div className="header-cell" data-target="cell">
                           <div data-target="inner" data-type="nft_token" className="header-cell-text" style={{maxWidth: "250px"}}>
                             <span className="header-cell-span">
                               <span>NFT Collection</span>
                             </span>
                           </div>
                          </div>
                          <div className="header-cell" data-target="cell">
                           <div data-target="inner" data-type="nft_token" className="header-cell-text" style={{maxWidth: "250px"}}>
                             <span className="header-cell-span">
                               <span>ID</span>
                             </span>
                           </div>
                          </div>
                          <div className="header-cell" data-target="cell">
                           <div data-target="inner" data-type="nft_token" className="header-cell-text" style={{maxWidth: "250px"}}>
                             <span className="header-cell-span">
                               <span>Value (ETH)</span>
                             </span>
                           </div>
                          </div>
                          <div className="header-cell" data-target="cell">
                           <div data-target="inner" data-type="nft_token" className="header-cell-text" style={{maxWidth: "250px"}}>
                             <span className="header-cell-span">
                               <span>Gas (ETH)</span>
                             </span>
                           </div>
                          </div>
                          <div className="header-cell" data-target="cell">
                           <div data-target="inner" data-type="nft_token" className="header-cell-text" style={{maxWidth: "250px"}}>
                             <span className="header-cell-span">
                               <span>Time</span>
                             </span>
                           </div>
                          </div>
                        </div>
                        {/*Row 0*/}
                        {
                          this.state.eventData.slice(0,250).map( (itemObj, itemIndex) => {
                            return (
                              <div className="cell-container" data-row={"row-" + itemIndex}>
                                <a className="table-cell" data-target="cell" data-column="0" target="_blank" role="button" href={`https://etherscan.io/address/${itemObj.address}`}>
                                  <div className="table-cell-text">
                                    <span className="table-cell-span">
                                      <span>
                                        <span>{itemObj.address}</span>
                                      </span>
                                    </span>
                                  </div>
                                </a>
                                <div className="table-cell" data-target="cell" data-column="1">
                                  <div className="table-cell-text">
                                    <span className="table-cell-span">
                                      <span>N/A</span>
                                    </span>
                                  </div>
                                </div>
                                <div className="table-cell" data-target="cell" data-column="2">
                                  <div className="table-cell-text">
                                    <span className="table-cell-span">
                                      <span>0</span>
                                    </span>
                                  </div>
                                </div>
                                <a className="table-cell" data-target="cell" data-column="3" target="_blank" role="button" href={`https://etherscan.io/address/${itemObj.counterparty}`}>
                                  <div className="table-cell-text">
                                    <span className="table-cell-span">
                                      <span>{itemObj.counterparty}</span>
                                    </span>
                                  </div>
                                </a>
                                <div className="table-cell" data-target="cell" data-column="5">
                                  <div className="table-cell-text">
                                    <span className="table-cell-span">
                                      <span>0</span>
                                    </span>
                                  </div>
                                </div>
                                <div className="table-cell" data-target="cell" data-column="5">
                                  <div className="table-cell-text">
                                    <span className="table-cell-span">
                                      <span>0</span>
                                    </span>
                                  </div>
                                  <div>
                                    <div data-target="sparkline" className="spark-cell">
                                      <div className="spark-cell-inner" style={{width: '0%'}}></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="table-cell" data-target="cell" data-column="6">
                                  <div className="table-cell-text">
                                    <span className="table-cell-span">
                                      <span>N/A</span>
                                    </span>
                                  </div>
                                </div>
                                <div className="table-cell" data-target="cell" data-column="7">
                                  <div className="table-cell-text">
                                    <span className="table-cell-span">
                                      <span>{moment(itemObj.timestamp).format("LLLL")}</span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                        {/*Row N+1*/}
                      </div>
                      ) : null}
                    </div>
                    {/*<footer className="">
                      <div className="">{'Showing 1 to 20 of 500'}</div>
                      <div className="">{'< [1] 2 ... 25 >'}</div>
                    </footer>*/}
                  </div>
                </section>
              </article>
            </div>
          </div>
        </main>

      </div>
    );
  }
}

/*
TODO: Mint master - Minting Volume 24H
Minting Volume 24H (ETH) - pie chart of the total eth volume breakdown per nft collection in the center, % allocations in ETH
Smart Minters 24H - pie chart of all nft collections and how many smart minters allocated for each

TODO: NFT God Mode
(Page 1)
  Collection name
  Deployed date
  NFTs in circulation
  Useful links (Etherscan/Opensea)
  Average price and volume (Price ETH = Purple Line chart, Volume ETH = Cyan Bar chart)
  Min/Avg/Max price range per day as Green/Purple/Pink line chart
  Min price (vs 24 hrs)
  Avg Price (vs 24 hrs)
  Max Price (vs 24 hrs)
  Volume (vs 24 hrs)
  Transactions (vs 24 hrs)
  Unique Buyers (vs 24 hrs)
  Individual Transactions (w/ extreme outlier drop checkbox, white SMA (12hr) line chart and yellow circles for Smart NFT buyers vs Common Buyers)
  Transactions & Buyers (Total transactions = purple, Unique buyers = white) line chart

(Page 2) Breakdown
Top Balances (Name, # NFTs, # NFTs recieved, # NFTs sent, Change (1D), Change (7D), Last In, Last Out)
Smart Money Over Time (19 different smart money categories)

(Page 3) Holders
NFT Seniority Distrobution (1, 7, 7-30, 30-60 days, over 90 days, etc) % NFTs/Addresses vs Days Since First NFT Aquired
Unique Addresses for NFT area chart
Top diamond hands (Buyer, Date, Balance)
Total NFTs belonging to diamond hands

(Page 4) Trades
Top 5 addresses buying (Price vs Time highlighted by category)
Chart view, Table view (Buyer, Price ETH, ID, Time)
Smart Money Buying
Chart view, Table view (Buyer, Price ETH, ID, Time)

(Page 5) Rarity
Show floor, set price max and sort by trait
Filter floor by trait
*/

export default App;
